<template>
  <base-section id="theme-features">
    <base-section-heading title="Products and Services">
      At AWM Telco we understand the power of connectivity. Our commitment goes
      beyond just providing internet services – we are your gateway to a world
      of seamless communication, unparalleled convenience, and endless
      possibilities.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal>
          </base-avatar-card>
        </v-col>
      </v-row>
      <!-- <v-col>
        <v-card elevation="2">asdsad</v-card>
      </v-col> -->
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        title: "SMS Messaging",
        icon: "mdi-cellphone-message",
        text: "Elevate your communication strategy with our SMS messaging tool, your all-in-one solution for secure One-Time Passwords (OTPs) and engaging marketing communications.",
      },
      {
        title: "A2P Wholesale Platform",
        icon: "mdi-cellphone",
        text: "A cloud-based white label business platform for any company that wants to be an A2P Messaging Wholesaler.",
      },
      {
        color: "primary",
        dark: true,
        title: "m-Marketing Platform",
        icon: "mdi-bullhorn-outline",
        text: "Transform your marketing strategy with our cutting-edge messaging marketing tool, designed to elevate your brand's outreach and engagement.",
      },
      {
        title: "Dashboards",
        icon: "mdi-monitor-dashboard",
        text: "The 1st Business Intelligence tool, designed and developed specifically for the mobile messaging, marketing & payments market. It is offered as an add-on to all our platforms.",
      },
      {
        title: "Mobile Coupons",
        icon: "mdi-ice-pop",
        text: "AWM offers to companies the capability to run mobile coupon campaigns, in order to enrich the coupon experience of users and build the Loyalty of its customers.",
      },
      {
        title: "24/7 Support",
        icon: "mdi-help-circle-outline",
        text: "When it gets mission critical, we are there to make sure everything runs smoothly. Choose the support level that fits your needs and we will be there to support your business.",
      },
    ],
  }),
}
</script>
